import React from 'react';
import { Grid, Typography, Slide, Box, Container, Button, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';

const projects = [
  {
      id: 1,
      title: "arozos",
      caption: "A web desktop OS in golang. It features a web desktop interface with an Windows-style layout, providing a user-friendly file manager, networking tools, and extensive file system support. Built on Debian with minimal resource requirements, it includes robust security options and extensibility through a JavaScript-like scripting interface, making it suitable for both desktop and mobile devices.",
      image: "./img/arozos.png",
      href: "https://github.com/tobychui/arozos"
  },
  {
      id: 2,
      title: "zoraxy",
      caption: "Zoraxy is a general-purpose HTTP reverse proxy and forwarding tool developed in Go, featuring an intuitive interface with in-system instructions. It supports HTTP/2, WebSocket proxying, custom headers, TLS/SSL setup with ACME auto-renewal, and IP blacklisting/whitelisting. Additional utilities include CIDR IP converters, mDNS scanners, and a web SSH terminal, making it easy to manage and integrate into existing systems.",
      image: "./img/zoraxy.png",
      href: "https://github.com/tobychui/zoraxy"
  },
  {
      id: 3,
      title: "OAuth Server",
      caption: "My home OAuth server, implemented in Go, provides a secure authentication solution featuring two-factor authentication (2FA) through Time-Based One-Time Passwords (TOTP) and message-based authentication. It utilizes a key-value database (kv-db) for efficient storage of usernames and passwords, ensuring both security and ease of access. This setup enables seamless integration with various applications while maintaining robust security standards.",
      image: "./img/home.png",
      href: "https://gitlab.alanyeung.co/"
  },
  {
      id: 4,
      title: "Photo",
      caption: "A collection of travel photos, featuring stunning views from diverse locations across Asia and America.",
      image: "./img/photo.png",
      href: "https://auth.alanyeung.co/?login=photo"
  }
];

const handleRedirect = (link) => {
  window.open(link, '_blank'); // Replace with your URL
};

const Project = () => {
  return (
    <>
    <iframe src="./particle/" style={{position: "absolute", width: "100%", height: "100%", top: "0", right: "0", border: "none", zIndex: "-1000"}}/>

    <Slide direction="right" in={true} timeout={500}>
                  <Container maxWidth="lg">
                  <Typography variant="h3" gutterBottom>What I am doing now</Typography>
                  {projects.map((project, index) => (
        <div key={index}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <img src={project.image} alt={project.title} style={{ width: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography style={{textAlign: "left"}} align="center" variant="h4">{project.title}</Typography>
              <Typography style={{textAlign: "left"}} variant="body2">{project.caption}</Typography>
              <br/>
              <Box display="flex" justifyContent="flex-start">
                <Button variant="contained" onClick={() => handleRedirect(project.href)}>Go</Button>
              </Box>
            </Grid>
          </Grid>
          {index < projects.length - 1 && <Divider style={{ margin: '16px 0' }} />}
        </div>
      ))}
</Container>
     </Slide>
     </>
  );
};

export default Project;
