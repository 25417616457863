import React, { useState, useEffect } from "react";
import { Box, Card, Typography, IconButton } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './Home.css';  // Import the CSS file for animations
import AboutMe from './aboutme.js'
import Project from './project.js'

const welcomeMessages = {
  en: "Welcome to alanyeung.co",
  zh: "歡迎來到 alanyeung.co",
  jp: "alanyeung.co へようこそ",
  fr: "Bienvenue sur alanyeung.co",
  es: "Bienvenido a alanyeung.co",
};

const Home = () => {
  const [currentLang, setCurrentLang] = useState("en");
  const [fade, setFade] = useState(true);
  const [animateOut, setAnimateOut] = useState(false);  // Track iframe section animation
  const [animateOut1, setAnimateOut1] = useState(false);  // Track iframe section animation
  const [showAbout, setShowAbout] = useState("home");    // Control visibility of About section

  // Cycle through the welcome messages every 10 seconds
  useEffect(() => {
    const languages = Object.keys(welcomeMessages);
    let index = 0;
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        index = (index + 1) % languages.length;
        setCurrentLang(languages[index]);
        setFade(true);
      }, 500); // Match fade-out duration
    }, 10000); // Every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = () => {
    setAnimateOut(true);  // Start the push-up fade-out animation for Section 1
    setTimeout(() => {
      setShowAbout("aboutme");  // After animation completes, show "About Me" section
    }, 1000);  // Match the animation duration (1 second)
  };

  const handleButton2Click = () => {
    setAnimateOut1(true);  // Start the push-up fade-out animation for Section 1
    setTimeout(() => {
      setShowAbout("project");  // After animation completes, show "About Me" section
    }, 1000);  // Match the animation duration (1 second)
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        //overflow: 'hidden',  // Prevent scrolling
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0,
      }}
    >
      {showAbout == "home" && (
        <Card
          className={animateOut ? "push-up-fade-out" : ""}  // Apply animation class if needed
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",  // Iframe occupies 100% height
            padding: 0,
            margin: 0,
          }}
        >
          <iframe
            title="iframe"
            src="./banner/"  // Replace with your iframe URL
            width="100%"
            height="100%"
            style={{ border: 0 }}
          ></iframe>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "50%",  // Gradient starts from the middle
              background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",  // Keep arrow and text at the bottom middle
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Typography
              variant="h2"
              color="white"
              sx={{
                position: "absolute",
                bottom: 20,
                right: 20,  // Welcome message at bottom right
                opacity: fade ? 1 : 0,  // Fading effect for "Welcome"
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              {welcomeMessages[currentLang]}
            </Typography>
            <Box sx={{ textAlign: "center", pb: 5 }}>
              {/* Button with arrow */}
              <IconButton onClick={handleButtonClick} sx={{ color: "white" }}>
                <ArrowDownwardIcon sx={{ fontSize: 40 }} />
              </IconButton>
              <Typography variant="h6" color="white">
                About Me
              </Typography>
            </Box>
          </Box>
        </Card>
      )}
      {showAbout == "aboutme" && (
        // Section 2: "About Me" content with fade-in effect
        // className="fade-in"
        <Box className={animateOut1 ? "push-up-fade-out" : "fade-in"} sx={{ textAlign: "center", p: 4 }}>
            <AboutMe click={handleButton2Click} />
        </Box>
      )}
      {showAbout == "project" && (
        // Section 2: "About Me" content with fade-in effect
        <Box className="fade-in" sx={{ textAlign: "center", p: 4 }}  >
            <Project/>
        </Box>
      )}
    </Box>
  );
};

export default Home;
