import React, { useEffect, useState  } from 'react';
import { Grid, Typography, Table, TableHead, TableBody, TableCell, TableRow, IconButton, Button, Slide, Box, Stack, Container, Divider } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@mui/lab';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import './aboutme.css';

const AboutMe = ({click}) => {

  const handleLinkClick = (link) => {
    // Open the link in a new tab
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
    <iframe src="./particle/" style={{position: "absolute", width: "100%", height: "100%", top: "0", right: "0", border: "none", zIndex: "-1000"}}/>
    <Grid container spacing={2}>
      {/* Left Hand Side: Personal Statements */}
      <Grid item xs={12} md={6}>
        <Slide direction="left" in={true} timeout={500}>
          <Box>
            {/* Table with Container for Width Limitation */}
            <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>About Me</Typography>
            <Typography variant="body1" paragraph>
            Hi! I’m Alan Yeung, a software engineer in AWS’s Developer Relations organization. I work primarily with Java and React and am passionate about open-source development, creating applications like Arozos and Zoraxy. My expertise lies in cloud security and authentication protocols, including SAML and OAuth2, which I leverage to build secure, scalable solutions.
          </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Education 1</TableCell>
                    <TableCell>University of Washington, Seattle Campus<br/>Seattle, WA{' '}<strong>GPA 3.75/4.00</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Education 2</TableCell>
                    <TableCell>The Chinese University of Hong Kong<br/>Hong Kong SAR</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Skills</TableCell>
                    <TableCell>C/C++, Java, React, golang, Docker, Kubernetes, Jenkins, CI/CD, TypeScript, AWS</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Current focus</TableCell>
                    <TableCell>Security, authentication/authorization system</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Languages</TableCell>
                    <TableCell>Chinese and English</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Living in</TableCell>
                    <TableCell>Seattle, WA</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              {/* Badges Section */}
              <Box mt={2}>
                <Typography variant="h6">Certification</Typography>
                <Stack direction="row" spacing={2}>
                <Box className={"img"} onClick={() => handleLinkClick("https://www.credly.com/badges/e41863dc-9fb7-4e44-86a5-23c3169697b1/public_url")} component="img" src="./img/cpp.png" alt="Badge 2" width={100} />
                  <Box className={"img"} onClick={() => handleLinkClick("https://www.credly.com/badges/313563b7-5d0a-475e-b1d1-472f17230ae7/public_url")} component="img" src="./img/saa.png" alt="Badge 1" width={100} />
                  <Box className={"img"} onClick={() => handleLinkClick("https://www.credly.com/badges/567b2cbf-270a-4d40-b801-2b7e7eb3209f/public_url")} component="img" src="./img/security.png" alt="Badge 2" width={100} />
                  {/* Add more badges as needed */}
                </Stack>
              </Box>
            </Container>
            <br/>
            <Divider/>
            {/* Icon Button Group for LinkedIn and GitHub */}
            <Box mt={2}>
            <IconButton size="large" href="https://linkedin.com/in/ho-yeung" target="_blank">
                <LinkedInIcon />
              </IconButton>
            <IconButton size="large" href="https://www.credly.com/users/ho-lun-yeung.c7268caa" target="_blank">
                <WorkspacePremiumIcon  />
              </IconButton>
              <IconButton size="large" href="https://github.com/yeungalan" target="_blank">
                <GitHubIcon />
              </IconButton>
            </Box>
          </Box>
        </Slide>
      </Grid>

      {/* Vertical Divider with Fade-in Effect */}
      <Grid item xs={12} md={1}>
        <Slide direction="up" in={true} timeout={500}>
          <Divider orientation="vertical" flexItem />
        </Slide>
      </Grid>

      {/* Right Hand Side: Employment History Timeline */}
      <Grid item xs={12} md={5}>
        <Slide direction="right" in={true} timeout={500}>
          <Timeline position="alternate">
          <TimelineItem>
              <TimelineOppositeContent>
                <Typography color="textSecondary">2024 - Present</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6">Amazon Web Services</Typography>
                <Typography color="textSecondary">Software Engineer I</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent>
                <Typography color="textSecondary">2023 - 2024</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6">Amazon Web Services</Typography>
                <Typography color="textSecondary">Cloud Engineer (Security and Cryptography)</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent>
                <Typography color="textSecondary">2022 - 2023</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6">University of Washington</Typography>
                <Typography color="textSecondary">Bachelor of engineering in computer engineering</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent>
                <Typography color="textSecondary">2019 - 2020</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6">The Chinese University of Hong Kong</Typography>
                <Typography color="textSecondary">Bachelor of engineering</Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Slide>
      </Grid>

      {/* Fixed Bottom Button with Arrow Only */}
      <Box position="fixed" bottom={20} left="50%" sx={{ transform: 'translateX(-50%)' }}>
        <Button
          variant="contained"
          color="primary" // Blue background
          sx={{ 
            borderRadius: '50%', // Circular shape
            height: 60, // Adjust the height as needed
            width: 60, // Adjust the width as needed
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} 
          //href="/projects"
          onClick={click}
        >
          <ArrowDownwardIcon sx={{ fontSize: 30 }} /> {/* Adjust icon size as needed */}
        </Button>
      </Box>
    </Grid>
    </>
  );
};

export default AboutMe;
